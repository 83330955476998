<template>
  <div>
    <b-table
      show-empty
      :responsive="true"
      :hover="true"
      :bordered="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="items"
      :fields="fields"
      :busy="submitting"
    >
      <template #table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Завантаження...</strong>
        </div>
      </template>
      <template #empty="scope">
        <div class="h4 text-center">Дані не знайдено</div>
      </template>
      <template #cell(financial_model_id)="data">
        {{
          financialModels
            .filter(obj => obj.id === data.value)
            .map(obj => obj.name)[0]
        }}
      </template>
    </b-table>

    <b-row>
      <b-col cols="auto" class="mr-auto p-3">
        <b-pagination
          :value="meta.page"
          prev-text="Попередня"
          next-text="Наступна"
          hide-goto-end-buttons
          :per-page="meta.per_page"
          :total-rows="meta.total"
          @change="onChangePage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ListTable",
  data() {
    return {
      sortDesc: false,
      sortBy: "",
      fields: [
        {
          key: "date",
          label: "Дата"
        },
        {
          key: "financial_model_id",
          label: "Профіль СПФМ"
        },
        {
          key: "short_term_liquidity",
          label: "Норматив короткострокової ліквідності"
        },
        {
          key: "cash",
          label: "Грошові кошти"
        },
        {
          key: "receivables",
          label: "Дебіторська заборгованість"
        },
        {
          key: "total_liabilities",
          label: "Загальна сума зобов'язань"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      items: "regulationsNbu/getRegulationsNbu",
      meta: "regulationsNbu/getMeta",
      filter: "regulationsNbu/getFilters",
      submitting: "regulationsNbu/getSubmitting",
      financialModels: "financialModels/getAllFinancialModels"
    }),
    sort: function() {
      if (this.sortDesc) return "-" + this.sortBy;
      return this.sortBy;
    }
  },
  methods: {
    onChangePage(page) {
      this.$store.commit("regulationsNbu/setPage", page);
      this.$store.dispatch("regulationsNbu/getRegulationsNbu");
    }
  },
  watch: {
    sort: {
      handler: function() {
        this.$store.commit("regulationsNbu/setSortBy", this.sort);
        if (this.meta.page > 1) {
          this.onChangePage(1);
        } else {
          this.$store.dispatch("regulationsNbu/getRegulationsNbu");
        }
      },
      deep: true
    }
  }
};
</script>
