<template>
  <b-card>
    <error-alert
      :errors="errors"
      message="Виникла помилка при пошуку нормативів НБУ!"
    />
    <filter-form />
    <list-table />
  </b-card>
</template>

<script>
import FilterForm from "./components/FilterForm";
import ListTable from "./components/RegulationsNbuList.vue";
import ErrorAlert from "@/components/ErrorAlert.vue";
import { mapGetters } from "vuex";
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$auth.can("view operation")) next();
      else next({ name: "Page404" });
    });
  },
  name: "RegulationsNbu",
  components: { ErrorAlert, FilterForm, ListTable },
  computed: {
    ...mapGetters({
      errors: "regulationsNbu/getErrors"
    })
  }
};
</script>
