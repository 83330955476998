<template>
  <ValidationObserver ref="formFilterValidation" v-slot="{ handleSubmit }">
    <b-form>
      <b-form-row>
        <b-col md="6" lg="4" xl="3">
          <ValidationProvider
            name="період"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="date_from_to"
          >
            <b-form-group label="Зазначте період">
              <date-range-picker
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
                :start-date="filter.dateFrom.value"
                :end-date="filter.dateTo.value"
                v-on:start-date-change="changeDateFrom"
                v-on:end-date-change="changeDateTo"
                :time-picker="false"
                :format-date="'dd.mm.yyyy'"
                style="width: 100%"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="6" lg="3">
          <ValidationProvider
            name="профіль СПФМ"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="financial_model_ids"
          >
            <b-form-group label="Профіль СПФМ">
              <multiselect
                v-model="filter.financialModel.value"
                :options="meFinancialModels.map((e) => e.id)"
                :multiple="true"
                :custom-label="customFinancialModelLabel"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :show-labels="false"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    type="checkbox"
                    :checked="
                      filter.financialModel.value.includes(scope.option)
                    "
                    @focus.prevent
                  />
                  {{ customFinancialModelLabel(scope.option) }}
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-button
            variant="primary"
            @click="getRegulationsNbu"
            class="mb-2"
            :disabled="submitting"
          >
            <i class="fa fa-search"></i> Розпочати пошук
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
import DateRangePicker from "@/components/DateRangePickerWrapper";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import filterList from "./../structure/filter";
import ErrorAlert from "@/components/ErrorAlert.vue";
import mixins from "@/mixins";
export default {
  name: "filter-form",
  components: { Multiselect, DateRangePicker, ErrorAlert },
  mixins: [mixins],
  data() {
    return {
      filter: filterList,
    };
  },
  computed: {
    ...mapGetters({
      meFinancialModels: "auth/meFinancialModels",
      errors: "regulationsNbu/getErrors",
      submitting: "regulationsNbu/getSubmitting",
    }),
  },
  methods: {
    changeDateFrom: function (v) {
      this.filter.dateFrom.value = v;
    },
    changeDateTo: function (v) {
      this.filter.dateTo.value = v;
    },
    getRegulationsNbu() {
      this.$store.commit("regulationsNbu/setFilter", this.filter);
      this.$refs.formFilterValidation.validate().then((success) => {
        if (!success) {
          return;
        }
        this.$store.commit("regulationsNbu/setPage", 1);
        this.$store.dispatch("regulationsNbu/getRegulationsNbu");
      });
    },
    customFinancialModelLabel(id) {
      return this.meFinancialModels.find((e) => e.id === id).name;
    },
  },
  watch: {
    errors: {
      handler: function (errors) {
        if (errors.code === 406) {
          this.$refs.formFilterValidation.setErrors(errors.description);
        }
      },
    },
  },
};
</script>
