import moment from "moment/moment";

export default {
  dateFrom: {
    name: "date",
    type: "gte",
    value: moment(new Date()).startOf("day").toDate(),
  },
  dateTo: {
    name: "date",
    type: "lte",
    value: moment(new Date()).toDate(),
  },
  financialModel: {
    name: "financial_model_id",
    type: "whereIn",
    value: [],
  },
};
